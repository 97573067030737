import React, { useState } from "react";

import "./SearchFilter.scss";

var classNames = require('classnames');

const SearchFilter = ({ onFilterChange, placeholder, splitChar, match }) => {

  const [input_value, setInputValue] = useState();

  const handleChange = (e) => {
    setInputValue(e.currentTarget.value)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const spiltedValues = input_value?.split(splitChar);
    let values = spiltedValues;
    if (match) {
      values = spiltedValues?.filter(w => w.match(match) && w !== '');
    }

    onFilterChange(values?.length === 0 ? undefined : values);
  };

  return (
    <form className="search_filter_molecule" onSubmit={handleSubmit}>
      <div className="input_field">
        <input type="text" placeholder={placeholder} onChange={handleChange} />
      </div>
      <input type="submit" value="Rechercher" />
    </form>
  );
};

SearchFilter.defaultProps = {
  splitChar: ' ', // whitespace
  match: '^[a-zA-Z0-9]*$', // Regex => Any alphanumeric character
};

export default SearchFilter;
