import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import MediaPageHeaderDesign from '../../../assets/images/mediapage-design-header.inline.svg';
import { IconBTAlert, IconBTDocument } from '../../../Atoms/Icons/Icons';
import ArticleHighlight from '../../../components/ArticleHighlight/ArticleHighlight';
import ArticleSingle from '../../../components/ArticleSingle/ArticleSingle';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Dropdown from '../../../components/Dropdown/Dropdown';
import PageInnerNav from '../../../components/PageInnerNav/PageInnerNav';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';
import AlertBlock from '../../../Molecules/AlertBlock/AlertBlock';
import { Button } from '../../../Molecules/Button/Button';
import FiltersTag from '../../../Molecules/FiltersTag/FiltersTag';
import SearchFilter from '../../../Molecules/SearchFilter/SearchFilter';
import { TitlePage } from '../../../Molecules/TitlePage/TitlePage';
import { isSentenceContainsKeywords } from '../../../Utils/SearchEngine';
import { getImage, getDocument } from '../../../Utils/Utils';

import './styles.scss';

const classNames = require('classnames');

const MediaPage = ({ data }) => {
  const intl = useIntl();
  const navigation = [
    {
      id: 'all',
      drupalTID: 0,
      name: intl.formatMessage({ id: 'media.categories.all' })
    },
    {
      id: 'news',
      drupalTID: 6,
      name: intl.formatMessage({ id: 'media.categories.actuality' })
    },
    {
      id: 'press-release',
      drupalTID: 7,
      name: intl.formatMessage({ id: 'media.categories.press' })
    },
    {
      id: 'publications',
      drupalTID: 8,
      name: intl.formatMessage({ id: 'media.categories.publications' })
    }
  ];
  const filters = [
    {
      id: 'annual_report',
      drupalTID: 12,
      name: intl.formatMessage({ id: 'media.filters.anualReport' })
    },
    {
      id: 'magazine_routes',
      drupalTID: 13,
      name: intl.formatMessage({ id: 'media.filters.magasinRoute' })
    },
    {
      id: 'plaquettes',
      drupalTID: 14,
      name: intl.formatMessage({ id: 'media.filters.plaquettes' })
    }
  ];
  const articles = data.articles.edges.map(a => a.node);
  const mediaPageData = data.media;
  const imageArray = data.allImages.edges;
  const showMoreVal = 5;
  const documentsArray = data.allDocuments.edges;

  const [highlightedArticle, setHighlightedArticle] = useState();
  const [filteredArticle, setFilteredArticle] = useState();
  const [filter_category, setFilterCategory] = useState(() => navigation.find(n => n.id = 'all'));
  const [filter_year, setFilteryear] = useState();
  const [filter_search, setFilterSearch] = useState();
  const [filter_tag, setFilterTag] = useState();
  const [currentNav, setCurrentNav] = useState(navigation.find(n => n.id = 'all'));
  const [showItem, setShowItem] = useState(showMoreVal);
  const [yearList, setYearList] = useState([]);
  const [paramYear, setParamYear] = useState();
  const [hasChangeCategory, setHasChangeCategory] = useState(false);

  let myYear = [];

  const handleFilterCategoryChange = (category) => {
    setFilterCategory(category);
    setCurrentNav(category);
    setShowItem(showMoreVal);

    switch (category.id) {
      case 'press-release':
        setFilterTag();
        break;
      case 'publications':
        setFilterSearch();
        break;
      default:
        setFilteryear();
        setFilterSearch();
        setFilterTag();
        break;
    }

    !hasChangeCategory ? setHasChangeCategory(true) : setParamYear(null);
    //setParamYear(null);
  };

  const handleFilterYearChange = (year) => {
    setFilteryear(year);
  };

  const handleFilterSearchChange = (search) => {
    setFilterSearch(search);
  };

  const handlefilterTagChange = (tag) => {
    setFilterTag(tag);
  };

  const onClickShowMore = () => {
    if (showItem < filteredArticle?.length) {
      setShowItem(showItem + showMoreVal);
    }
  };

  const getParamFromUrl = (paramName) => {
    let queries = location.search.substring(1).split('&'),
      processed = {};
    for (let query of queries) {
      let [name, value] = query.split('=');
      processed[decodeURIComponent(name)] = value ? decodeURIComponent(value) : '';
    }
    return processed;
  };

  const setdropDownList = () => {
    let i = 0;
    const articleCatSelected = articles.filter(a => a.relationships.field_category.drupal_internal__tid === filter_category.drupalTID);
    articleCatSelected?.forEach((article) => {
      if (myYear.filter(a => a.label === article?.fields?.publicationYear).length <= 0) {
        myYear.push({
          id: i++,
          label: article?.fields?.publicationYear
        });
      }
    });

    setYearList(myYear);
    setFilteryear(myYear[0]);
  };

  const handleUrlDirectOpen = (e) => {
    e?.preventDefault;
    /*let url_string = window.location.href; //window.location.href
  let url = new URL(url_string);*/

    let openTab = e ? e : getParamFromUrl()?.openTab;
    setParamYear(getParamFromUrl().year);
    let tabObj = {
      id: 'all',
      drupalTID: 0
    };

    if (openTab === 'press-release') {
      tabObj = {
        id: 'press-release',
        drupalTID: 7
      };
    }
    else if (openTab === 'publications') {
      tabObj = {
        id: 'publications',
        drupalTID: 8
      };
    }
    else if (openTab === 'news') {
      tabObj = {
        id: 'news',
        drupalTID: 6
      };
    }
    //setdropDownList();
    handleFilterCategoryChange(tabObj);
    setCurrentNav(tabObj);

    // setFilteryear(paramYear ? yearList.filter(a => a.label === paramYear) : yearList[0]);
  };

  useEffect(() => {
    setdropDownList();
    setFilteryear(paramYear ? myYear.filter(a => a.label === parseInt(paramYear))[0] : myYear[0]);
  }, [filter_category]);

  useEffect(() => {
    //setdropDownList();
    handleUrlDirectOpen();
  }, []);

  // Effect: Filter
  useEffect(() => {
    const processfilter = () => {
      let updatedArticles = articles;

      if (filter_category.drupalTID > 0) {
        updatedArticles = updatedArticles.filter(a => a.relationships.field_category.drupal_internal__tid === filter_category.drupalTID);
      }
      if (filter_year) {
        updatedArticles = updatedArticles.filter(a => a.fields.publicationYear == filter_year.label); // Actualy compare string between int!
      }
      if (filter_search) {
        updatedArticles = updatedArticles.filter(a => isSentenceContainsKeywords(filter_search, [a.title, a.body.summary])); // only lookup in title & summary
      }
      if (filter_tag) {
        updatedArticles = updatedArticles.filter(a => a.relationships.field_tags.some(t => t.drupal_internal__tid === filter_tag.drupalTID));
      }

      setHighlightedArticle(updatedArticles.find(a => a));
      setFilteredArticle(updatedArticles);
    };

    processfilter();
  }, [filter_category.drupalTID, filter_search, filter_tag, filter_year]);

  return (
    <Layout imagesArray={imageArray}>
      <Seo title={mediaPageData?.title} />
      <div className={classNames('page_template', 'page_media')}>
        <MediaPageHeaderDesign className="media_top_design" />
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              currentPage: {
                title: mediaPageData?.title, url: '/' + mediaPageData?.langcode + mediaPageData?.path?.alias
              },
              locale: mediaPageData?.langcode
            }
            }
          />
          <TitlePage
            color="color_dark_bleu"
            title={mediaPageData?.title}
          />
          <PageInnerNav
            nav={navigation}
            customClass={(filter_category.id === 'press-release' || filter_category.id === 'publications') ? 'little_marge' : ''}
            onFilterChange={handleFilterCategoryChange}
            currentNavFromParent={currentNav}
          />
          {(filter_category.id === 'press-release' || filter_category.id === 'publications') && (
            <div className="filters">
              <div className="flex_line">
                {(filter_category.id === 'press-release' || filter_category.id === 'publications') && (
                  <Dropdown
                    customClass="dropdown-filter"
                    list={yearList}
                    selected={filter_year}
                    onChange={handleFilterYearChange}
                  />
                )}
                {filter_category.id === 'press-release' && (
                  <SearchFilter
                    className="search-filter"
                    placeholder="Que recherchez-vous ?"
                    onFilterChange={handleFilterSearchChange}
                  />
                )}
              </div>
              {filter_category.id === 'publications' && (
                <FiltersTag
                  tags={filters}
                  currentTag={filter_tag}
                  customClass="only_mobile_tablet"
                  onFilterChange={handlefilterTagChange}
                />
              )}
            </div>
          )}
          <div className="articles_container">
            <div className="list_articles">
              {/* remove ArticleHighlight when filter_category.id === 'publications'  */}
              {highlightedArticle && filter_category.id != 'publications' &&
                <ArticleHighlight
                  title={highlightedArticle.title}
                  reading_time={highlightedArticle.fields.readingTime.format}
                  linkTo={highlightedArticle.fields.slug}
                  image={getImage(imageArray, highlightedArticle.relationships?.field_image?.drupal_internal__mid)}
                />
              }
              <ul>
                {filteredArticle && filteredArticle.map((article, i) => (
                  <li key={i} className={classNames(i < showItem ? 'show-item' : 'hide-item')}>
                    <ArticleSingle
                      title={article.title}
                      reading_time={article.fields.readingTime.format}
                      summary={article.body?.summary}
                      linkTo={(article?.relationships?.field_category?.drupal_internal__tid === 8 && currentNav.id === 'publications') ? getDocument(documentsArray, article.relationships?.field_media_pdf?.drupal_internal__mid)?.relationships?.field_media_document?.localFile?.publicURL : article?.fields?.slug}
                      image={getImage(imageArray, article.relationships?.field_image?.drupal_internal__mid)}
                      category={navigation.filter(a => a.drupalTID === article.relationships.field_category.drupal_internal__tid)[0]}
                      currentNav={currentNav.id}
                    />
                  </li>
                ))}
              </ul>
              <div className="btn_container_desktop">
                <Button
                  label={intl.formatMessage({ id: 'common.showMore' })}
                  icon="plus"
                  onClick={onClickShowMore}
                  customClass={showItem >= filteredArticle?.length ? 'hide' : ''}
                />
              </div>
            </div>
            <div className="btn_container_mobile">
              <Button
                label={intl.formatMessage({ id: 'common.showMore' })}
                icon="plus"
                onClick={onClickShowMore}
                customClass={showItem >= filteredArticle?.length ? 'hide' : ''}
              />
            </div>
            <div className="col_2_items">
              {filter_category.id === 'publications' &&
                <FiltersTag
                  tags={filters}
                  currentTag={filter_tag}
                  customClass="only_desktop"
                  onFilterChange={handlefilterTagChange}
                />
              }

              {mediaPageData.relationships.field_blocs.map((block, i) => {
                let ctaType = block?.field_type_bloc_contact;
                switch (block.__typename) {
                  case 'block_content__block_contact':
                    return (
                      <div key={i} className='cta_block'>
                        {((ctaType === 'alert' && currentNav.drupalTID != 7) || (ctaType === 'document' && currentNav.drupalTID != 8) || (ctaType === 'actualites' && currentNav.drupalTID != 6 && currentNav.drupalTID != 0)) &&
                          <AlertBlock
                            link="#"
                            label={block.body?.processed}
                            icon={block?.field_type_bloc_contact === 'document' ? <IconBTDocument /> : <IconBTAlert />}
                            directopen={block?.field_type_bloc_contact === 'alert' ? 'press-release' : (block?.field_type_bloc_contact === 'document' ? 'publications' : 'news')}
                            onClick={handleUrlDirectOpen}
                          />}
                      </div>
                    );
                }
              })}

            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

MediaPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

export const query = graphql`
      query MediaTemplateQuery(
      $locale: String!
      $slug: String!
      $imagesID: [Int!]
      $documentsID: [Int!]
      ) {
        media: nodePage(langcode: {eq: $locale}, path: {alias: {eq: $slug}}) {
        langcode
      path {
        alias
      }
      title
      relationships {
        field_blocs {
        __typename
          ... on Node {
        ...on block_content__block_contact {
        field_type_bloc_contact
              body {
        processed
      }
            }
          }
        }
      }
    }
      articles: allNodeArticle(
      filter: {langcode: {eq: $locale}}
      sort: {order: DESC, fields: created}
      ) {
        edges {
        node {
        langcode
          path {
        alias
      }
      created
      title
      relationships {
        field_category {
        drupal_internal__tid
      }
      field_tags {
        drupal_internal__tid
      }
      field_image {
        drupal_internal__mid
      }
      field_media_pdf {
        drupal_internal__mid
      }
          }
      body {
        summary
      }
      fields {
        slug
            publicationYear
      readingTime {
        format
      }
          }
        }
      }
    }
      allImages : allMediaImage(filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imagesID}}) {
        edges {
        node {
        drupal_internal__mid
          relationships {
        field_media_image {
        localFile {
        publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                  mobile: fixed(width: 456, height: 456) {
                    ...GatsbyImageSharpFixed
                  }
                  desktop: fixed(width: 1256, height: 814) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
      langcode
        }
      }
    }

      allDocuments: allMediaDocument(filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $documentsID}}) {
        edges {
        node {
        langcode
          field_media_document {
        description
      }
      relationships {
        field_media_document {
        localFile {
        publicURL
                size
      name
              }
            }
          }
      drupal_internal__mid
        }
      }
    }
  }
      `;

export default MediaPage;
