import React from "react";
import "./FiltersTag.scss";

var classNames = require('classnames');

const FiltersTag = ({ tags, currentTag, customClass, onFilterChange }) => {
  const handleChange = (e, tag) => {
    e.preventDefault();
    onFilterChange(currentTag?.id === tag.id ? false : tag);
  }
  return (
    <div className={classNames('filters_tag_component', customClass || null)}>
      <p className="title">Filtres</p>
      <ul>
        {
          tags.map((tag, i) => (
            <li key={i}>
              <a href="#" className={classNames({ 'active': currentTag?.id === tag.id })} onClick={(event) => handleChange(event, tag)}>{tag.name}</a>
            </li>
          ))
        }
        <li>

        </li>
      </ul>
    </div>
  )
}

export default FiltersTag;
