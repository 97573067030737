import React, { useEffect, useState } from 'react';

import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import DefaultImage from '../../assets/images/single-article-visu-desktop.png';
import { IconLectureTime, HorizontalYellowLineShape } from '../../Atoms/Icons/Icons';
import DocumentView from '../../Molecules/DocumentView/DocumentView';
import SocialShare from '../../Molecules/SocialShare/SocialShare';
import './ArticleSingle.scss';

const classNames = require('classnames');

const ArticleSingle = ({ category, title, summary, reading_time, linkTo, image, customClass, currentNav }) => {
  const show_lecture_time = category?.id != 'publications' ? true : false;
  const show_summary = category?.id != 'publications' ? true : false;
  const show_socialshare = category?.id != 'publications' ? true : false;
  const [navCur, setNavCur] = useState();

  useEffect(() => {
    setNavCur(currentNav);
  }, [currentNav]);

  const image_sources = [{
    ...image?.childImageSharp.mobile,
    aspectRatio: 1
  }];

  return (
    <div className={classNames('article_single', customClass)}>
      <div className={classNames('visuel', { 'no_visual': !image })}>
        {image &&
          (
            category.drupalTID === 8 ? <a href={linkTo} target='_blank' rel="noreferrer"><Img
              style={{ height: '100%', width: '100%' }}
              fluid={image_sources}
            /></a>
              :
              <Img
                style={{ height: '100%', width: '100%' }}
                fluid={image_sources}
              />
          )
        }
        {show_lecture_time &&
          <p className="lecture_time">
            <IconLectureTime className="time_icon" />
            <span>{reading_time}</span>
            <HorizontalYellowLineShape className="bottom_line" />
          </p>
        }
      </div>
      <div className={classNames('text', { 'no_summary': !show_summary })}>
        {show_socialshare &&
          <SocialShare
            title={title}
            linkTo={linkTo}
            hidemobile={true}
          />
        }
        <div className={classNames('title_description', category?.id === 'publications' && currentNav === 'publications' ? 'show_document_icon' : '')}>
          <h2>
            {category?.id === 'publications' && currentNav === 'publications' ? (
              <a href={linkTo} target='_blank' rel="noreferrer">{title}</a>
            ) :
              (
                <Link to={linkTo}>{title}</Link>
              )}

          </h2>
          {(category?.id === 'publications' && currentNav === 'publications' && linkTo) &&
            <DocumentView
              data={[
                {
                  name: title,
                  size: null,
                  link: linkTo,
                  showName: false

                }
              ]}
            />
          }
          {show_summary &&
            <div dangerouslySetInnerHTML={{ __html: summary }} />
          }
        </div>
      </div>
    </div>
  );
};

ArticleSingle.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
  reading_time: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  image: PropTypes.object,
  imageMobile: PropTypes.object
};

export default ArticleSingle;
